import UrlResolverPageComponent from '@bluheadless/ui/src/page-components/url-resolver/url-resolver'
import RichSnippetBreadcrumbs from '@bluheadless/ui/src/particles/rich-snippets/breadcrumbs'
import Head from 'next/head'
import { mediaUrl } from '@bluheadless/ui-logic/src/assets'
import withUrlResolver from '@bluheadless/ui-logic/src/hof/withUrlResolver'
import { useStore } from '@bluheadless/ui-logic/src/providers/store'
import { removeTrailingSlashes } from '@bluheadless/ui-logic/src/utils/url'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'
import { useUser } from '@bluheadless/ui-logic/src/providers/user'
import { useUserUniqueId } from '@bluheadless/ui-logic/src/hooks/useUserUniqueId'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { WITHOUT_LAYOUT } from '@bluheadless/ui/src/layouts/variants'
import { usePageContext } from '@bluheadless/ui-logic/src/providers/page/page'
import { getCanonicalUrl, getMetaRobots } from '@bluheadless/ui-logic/src/catalog/paginator'

export const getServerSideProps = withUrlResolver

const UrlResolver = ({
	entityType,
	absoluteUrl,
	hreflang,
	canonical,
	breadcrumbs,
	seoMeta: { title: metaTitle, description: metaDescription, keywords: metaKeywords, robots } = {},
	ogData: { title: ogTitle, description: ogDescription, image: ogImage, url: ogUrl } = {},
	...props
}) => {
	const store = useStore()

	const { formatMessage } = useIntl()

	const richSnippetsBreadcrumbsItems =
		breadcrumbs?.length > 0
			? [{ label: formatMessage({ id: 'breadcrumbs_home' }), url: store.uri }].concat(
					breadcrumbs.map(({ label, path }) => ({ label, url: removeTrailingSlashes(store.uri) + path }))
			  )
			: entityType === 'product'
			? [{ label: formatMessage({ id: 'breadcrumbs_home' }), url: store.uri }].concat({
					label: props.name,
					url: canonical,
			  })
			: []

	const { pageViewDetails } = useTrackingsActions()
	const { entityType: pageContext, __bhEntityId: pageId } = usePageContext()
	const [{ isLoggedIn }] = useUser()

	const userUniqueId = useUserUniqueId()
	const { locale } = useConfig()

	useEffect(() => {
		if (
			!userUniqueId ||
			entityType === 'product' /* skip pageViewDetails for product, will be evaluated in ProductPageComponent */
		) {
			return
		}
		pageViewDetails({
			pageType: entityType,
			params: {
				isLoggedIn,
				userUniqueId,
				locale,
				...props,
			},
			pageContext,
			pageId,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, userUniqueId, entityType, props.entityId, pageContext, pageId, pageViewDetails, locale])

	const {
		canonical: canonicalUrl,
		prev,
		next,
	} = getCanonicalUrl({ canonical, page: props?.page, lastPage: props?.lastPage, entityType })

	const metaRobots = getMetaRobots({ robots, page: props?.page, entityType })

	return (
		<>
			<Head>
				{canonical && (
					<>
						<link rel="canonical" href={canonicalUrl} />
						{prev && <link rel="prev" href={prev} />}
						{next && <link rel="next" href={next} />}
					</>
				)}
				{hreflang &&
					Object.keys(hreflang).map((id) => (
						<link key={id} rel="alternate" href={hreflang[id].url} hrefLang={hreflang[id].code} />
					))}
				<title>{metaTitle}</title>
				<meta name="description" content={metaDescription} />
				<meta name="keywords" content={metaKeywords} />
				<meta name="robots" content={metaRobots} />
				<meta property="og:title" content={ogTitle} />
				<meta property="og:description" content={ogDescription} />
				{/* if fullUrl is provided don't use mediaUrl */}
				<meta
					property="og:image"
					content={ogImage ? (/^https?:\/\//i.test(ogImage) ? ogImage : mediaUrl(ogImage)) : null}
				/>
				<meta property="og:url" content={ogUrl || absoluteUrl} />
			</Head>
			<RichSnippetBreadcrumbs items={richSnippetsBreadcrumbsItems} />
			<UrlResolverPageComponent entityType={entityType} breadcrumbs={breadcrumbs} {...props} />
		</>
	)
}
export default UrlResolver

UrlResolver.withLayout = WITHOUT_LAYOUT
UrlResolver.withRestrictedLayout = WITHOUT_LAYOUT
